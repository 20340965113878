import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Box, Stack } from '@mui/material';
import NavList from './NavList';
// ----------------------------------------------------------------------
function NavSectionMini({ data, sx, ...other }) {
    return (_jsx(Stack, { spacing: 0.5, alignItems: "center", sx: {
            px: 0.75,
            ...sx,
        }, ...other, children: data.map((group, index) => (_jsx(Items, { items: group.items, isLastGroup: index + 1 === data.length }, group.subheader))) }));
}
export default memo(NavSectionMini);
function Items({ items, isLastGroup }) {
    return (_jsxs(_Fragment, { children: [items.map((list) => (_jsx(NavList, { data: list, depth: 1, hasChild: !!list.children }, list?.title + list.path))), !isLastGroup && (_jsx(Box, { sx: {
                    width: 24,
                    height: '1px',
                    bgcolor: 'divider',
                    my: '8px !important',
                } }))] }));
}
